import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker,
} from '@material-ui/pickers';
import MuiAlert from '@material-ui/lab/Alert';
import {
    submitFormSessionCreate,
    resetSessionCreateForm,
    submitFormSessionUpdate,
    submitFormSessionRemove,
    fetchCancelledSessions,
} from 'actions/session';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Checkbox from '@material-ui/core/Checkbox';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FormControlLabel } from '@material-ui/core';
import DialogTitle from './DialogTitle';
import { SdStorage } from '@material-ui/icons';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    paper: {
        borderRadius: '20px',
    },
    model: {
        boxShadow: '0px 0px 14px #0000000D',
        opacity: 1,
        borderRadius: '20px',
    },
    inputrow: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '15px',
        [theme.breakpoints.down(500)]: {
            display: 'block',
            justifyContent: 'space-between',
        },
    },
    datePicker: {
        marginTop: '0px',
        [theme.breakpoints.down(500)]: {
            width: '100%',
        },
    },
    inputLabel: {
        letterSpacing: '0px',
        color: '#043B84',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    inputFeild: {
        width: '100%',
        background: '#F3F3F3 0% 0% no-repeat padding-box',
        borderRadius: '2px',
        height: '53px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    titleRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
    },
    deleteIcon: {
        position: 'absolute',
        right: '23px',
        top: '43px',
        color: 'red',
    },
    confirmationDialog: {
        padding: '20px',
    },
    confirmationActions: {
        paddingTop: '20px',
        display: 'flex',
        justifyContent: 'center',
    },
    deleteConfirmBtn: {
        color: 'red',
    },
    validateMsg: {
        color: 'red',
        fontSize: 16,
    },
    createSession: {
        marginBottom: '20px',
    },
}));

function PaperComponent(props) {
    return (
        <Draggable
            handle="#session-create-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

/* const DialogTitle = withStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing(2),
    },
    mainFont: {
        color: '#004F8B',
        'font-weight': 1000,
    },
    closeButton: {
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        right: 0,
    },
}))(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography {...other}>
            <div className={classes.root}>
                <div>
                    <Typography variant="h2" className={classes.mainFont}>
                        {children}
                    </Typography>
                </div>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                        onTouchEnd={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </div>
        </MuiDialogTitle>
    );
}); */

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
}))(MuiDialogActions);

const SubmitButton = withStyles(theme => ({
    root: {
        backgroundColor: '#004F8B',
        width: '100%',
        '&:hover': {
            backgroundColor: '#004F8B',
        },
        height: '50px',
    },
}))(Button);

const SessionAdd = ({
    submitFormSessionCreate,
    submitFormSessionUpdate,
    submitFormSessionRemove,
    confirmation,
    seletedRowData,
    resetSessionCreateForm,
    error,
    districtsList,
    vaccineTypeList,
    cancelledSessionList,
    updateCancelledSessionList,
    ageGroupList,
    doses,
}) => {

    const classes = useStyles();
    const [selectedDataRow, setSelectedDataRow] = React.useState('');

    useEffect(() => {
        if (seletedRowData) {
            setSelectedDataToForm(seletedRowData.select);
            setEditMode(true);
            setOpen(true);
            setLoading(false);
            setSelectedDataRow(seletedRowData.select);
        }
    }, [seletedRowData]);

    /** Handle model from the create session button Start */
    const handleClickOpen = () => {
        setLoading(false);
        setEditMode(false);
        setOpen(true);
    };
    const handleClose = () => {
        handleSessionFormReset();
        handleResetSessionObj();
        setOpen(false);
    };

     /** Handle model from the create session button End */

    const todayDate = new Date();
    const tomorrowDate = new Date(todayDate);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);

    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);

    const [district, setDistrict] = React.useState('');
    const [mohCenter,setMohCenter] = React.useState('');
    const [mohCenterList,setMohCenterList] = React.useState([]);
    const [vaccinationCenter,setVaccinationCenter] = React.useState('');
    const [vaccinationCenterList,setVaccinationCenterList] = React.useState([]);
    const [sessionDate, setSessiondate] = React.useState(moment(todayDate).format(moment.HTML5_FMT.DATE));
    const [sessionStartTime, setSessionStartTime] = React.useState(new Date());
    const [sessionEndTime, setSessionEndTime] = React.useState(new Date());
    const [sessionVaccines, setSessionVaccines] = React.useState(0);
    const [vaccineType, setVaccineType] = React.useState('');
    const [vaccineDose, setVaccineDose] = React.useState('');
    const [vaccineLocation, setVaccineLocation] = React.useState('');
    const [canDelete, setCanDelete] = React.useState(false);
    const [minVaccineCount, setMinVaccineCount] = React.useState(0);
    const [sessionStatus, setSessionStatus] = React.useState('');
    const [isAbsorb, setIsAbsorb] = React.useState(false);
    const [cancelledSession, setCancelledSession] = React.useState('');
    const [ageGroup,setAgeGroup] = React.useState('');
 
    const validForm =
        district !== '' &&
        mohCenter !== '' &&
        vaccinationCenter !== '' &&
        sessionStartTime !== '' &&
        sessionEndTime !== '' &&
        sessionStartTime < sessionEndTime &&
        sessionVaccines > minVaccineCount && 
        vaccineType !== '' &&
        vaccineDose !== '' &&
        ageGroup !== '';

        const isSessionUpdate = validForm && ((editMode && canDelete && ((isAbsorb && cancelledSession !== '') || !isAbsorb)) || (editMode && isAbsorb && cancelledSession !== ''));
        const isCreate = validForm && ((!editMode && isAbsorb && cancelledSession !== '') || (!editMode && !isAbsorb));

        /** Change state when change inputs */
        const handleDistrictChange = (event, value) => {
            resetDistrict();
            if( value !== null){
                setDistrict(value);
                setMohCenterList(value.districtFullListMohDto);
            }
        };

        const handleMohCenterChange = (event, value) => {
            resetMohCeter();
            if( value !== null){
                setMohCenter(value);
                setVaccinationCenterList(value.districtFullListLocationDto);
            }
        };

        const handleVaccinationCenterChange = (event, value) => {
            setVaccinationCenter('');
            if( value !== null){
                setVaccinationCenter(value);
            }
        };

        const handleVaccineTypeChange = (event, value) => {
            setVaccineType(value || '');
        };

        const handleVaccinationDoseChange = (event, value) => {
            setVaccineDose(value || '');
        };

        const handleSessionVaccineLocationChange = event =>{
            let temp = event.target.value || '';
            setVaccineLocation(temp);
        }

        const resetDistrict = () => {
            setDistrict('');
            setMohCenter('');
            setVaccinationCenter('');
            setMohCenterList([]);
            setVaccinationCenterList([]);

        }

        const resetMohCeter = () => {
            setMohCenter('');
            setVaccinationCenter('');
            setVaccinationCenterList([]);
        }

        const handleSessionDateChange = event => {
            let tmpDate = new Date(event);
            tmpDate = moment(tmpDate).format(moment.HTML5_FMT.DATE);
            setSessiondate(tmpDate);
        };

        const handleSessionStartTimeChange = event => {
            let tmpStartTime = event;
            setSessionStartTime(new Date(tmpStartTime));
        };

        const handleSessionEndTimeChange = event => {
            let tmpEndTime = event;
            setSessionEndTime(new Date(tmpEndTime));
        };

        const handleSessionVaccinesChange = event => {
            let tmpSessionVaccines = Math.abs(event.target.value);
            setSessionVaccines(tmpSessionVaccines);
        };

        const handleAgeGroupChange = (event, value) => {
            setAgeGroup(value || '');
        };

        /** Change state when change input End */

        /** Submit form for create a session */
        const handleSessionCreateSubmission = () => {
            setLoading(true);
            submitFormSessionCreate({
                id: null,
                date: moment(sessionDate).format(moment.HTML5_FMT.DATE).toString(),
                fromTime: moment(sessionStartTime).format('HH:mm'),
                toTime: moment(sessionEndTime).format('HH:mm'),
                vaccines: Number.parseInt(sessionVaccines),
                appoinments: Number.parseInt(sessionVaccines),
                centersId: vaccinationCenter.locationId,
                sessionVaccineType: Number.parseInt(vaccineType.id),
                doseType: vaccineDose.id,
                locations: [vaccinationCenter.locationId],
                districtId: district.districtId,
                isAbsorb: isAbsorb.toString(),
                cancelledSessionId: cancelledSession.sid,
                minage: Number.parseInt(ageGroup.group.split(' - ')[0]),
                maxage: ageGroup.group.split(' - ')[1] === 'Above' ? 150 : Number.parseInt(ageGroup.group.split(' - ')[1]),
            });
        }; 
        /** Submit form for create a session End*/

        /** submit for update a session  */
        const handleSessionUpdateSubmission = () => {
            setLoading(true);
            submitFormSessionUpdate({
                id: seletedRowData.select.sid,
                date: moment(sessionDate).format(moment.HTML5_FMT.DATE).toString(),
                fromTime: moment(sessionStartTime).format('HH:mm'),
                toTime: moment(sessionEndTime).format('HH:mm'),
                vaccines: Number.parseInt(sessionVaccines),
                appoinments: Number.parseInt(sessionVaccines),
                centersId: vaccinationCenter.locationId,
                sessionVaccineType: Number.parseInt(vaccineType.id),
                doseType: vaccineDose.id,
                locations: [vaccinationCenter.locationId],
                districtId: district.districtId,
                isAbsorb: isAbsorb.toString(),
                cancelledSessionId: cancelledSession.sid,
                minage: Number.parseInt(ageGroup.group.split(' - ')[0]),
                maxage: ageGroup.group.split(' - ')[1] === 'Above' ? 150 : Number.parseInt(ageGroup.group.split(' - ')[1]),
            });
        };
        /** submit for update a session END */

        /** set data to form when update session */
        const setSelectedDataToForm = selectedData => {   
            const selectedDistrict = districtsList.filter(
                res => res.districtId === selectedData.districtRespDto.districtId
            )[0];

            const mohCenterList = selectedDistrict.districtFullListMohDto;
            const mohCenter = mohCenterList.filter(
                res => res.mohId === selectedData.mohRespDto.mohId
            )[0];
            
            const vaccineCenterList = mohCenter.districtFullListLocationDto;

            const vaccineCenter = vaccineCenterList.filter(
                res => Number.parseInt(res.locationId) === selectedData.locationDetailsResDto[0].lid
            )[0];

            const vaccinetype = vaccineTypeList.filter(
                res => res.code === selectedData.vaccineRespDto.vaccineCode
            )[0];

            let selectedAge = selectedData.minage + ' - ' + (selectedData.maxage === 150 ? 'Above': selectedData.maxage);
            const ageGroupSelected = ageGroupList.filter(
                res => res.group === selectedAge
            )[0];

            let doseType = doses.filter(dose => dose.id === selectedData.doseType)[0];
           
            setDistrict(selectedDistrict);
            setMohCenter(mohCenter);
            setMohCenterList(mohCenterList);
            setVaccinationCenter(vaccineCenter);
            setVaccinationCenterList(vaccineCenterList);
            setSessiondate(selectedData.sdate);
            setSessionStartTime(new Date('2021T' + selectedData.fromTime));
            setSessionEndTime(new Date('2021T' + selectedData.gettoTime));
            setSessionVaccines(selectedData.getAppoinments);
            setVaccineType(vaccinetype);
            setVaccineDose(doseType);
            setVaccineLocation('');
            setCanDelete(selectedData.canDelete);
            setSessionStatus(selectedData.sessionStatus);
            setAgeGroup(ageGroupSelected);
        };

        /** set data to form when update session End*/

        /** Reset Form function */
        const handleSessionFormReset = event => {
            resetSessionCreateForm({});
            handleResetSessionObj();
            setOpen(false);
            updateCancelledSessionList();
        };

        const handleResetSessionObj = () => {
            setDistrict('');
            setMohCenter('');
            setMohCenterList([]);
            setVaccinationCenter('')
            setVaccinationCenterList([]);
            setSessiondate(todayDate);
            setSessionStartTime(new Date());
            setSessionEndTime(new Date());
            setSessionVaccines(0);
            setVaccineType('');
            setVaccineDose('');
            setVaccineLocation('');
            setEditMode(false);
            setCancelledSession('');
            setIsAbsorb(false);
            setSessionStatus('');
            setMinVaccineCount(0);
            setAgeGroup('');
        };
        /** Reset Form function */    

        /** open/close Delete confirmation alert */
        const handleOpenAlert = () => {
            setOpenAlert(true);
        };
    
        const handleCloseAlert = () => {
            setOpenAlert(false);
        };
        /** open/close Delete confirmation alert End*/

        /** Delete a session */
        const handleRemoveSession = () => {
            setOpenAlert(false);
            setLoading(true);
            submitFormSessionRemove({
                id: seletedRowData.select.sid,
            });
        };
        /** Delete a session End*/

        /** isAbsorb function */

        const handleChangeCancelledSession = (event,value) => {
            if(value !== null){
                setCancelledSession(value);
                changeDataAcordingToIsAbsorb(value);
            }
        };

        const handleChangeIsAbsorbCheckbox = (event,value) => {
            setIsAbsorb(event.target.checked);
        };

        const changeDataAcordingToIsAbsorb = (cancelledSession) => {
            const district = districtsList.filter(district => district.districtId === cancelledSession.districtId)[0];
            const moh = district.districtFullListMohDto.filter(moh => moh.mohId === cancelledSession.mohId)[0];
            const vaccineCenter = moh.districtFullListLocationDto.filter(vaccine => vaccine.locationId === cancelledSession.centerId)[0];
            const vaccineType = vaccineTypeList.filter(vaccineType => vaccineType.id === cancelledSession.vaccineType)[0];
            
            let selectedAge = cancelledSession.minage + ' - ' + (cancelledSession.maxage === 150 ? 'Above': cancelledSession.maxage);
            const ageGroupSelected = ageGroupList.filter(res => res.group === selectedAge)[0];

            const doseType = doses.filter(dose => dose.id === cancelledSession.doseType)[0];

            setDistrict(district);
            setMohCenter(moh);
            setVaccinationCenter(vaccineCenter);
            setVaccinationCenterList(moh.districtFullListLocationDto);
            setVaccineType(vaccineType);
            setVaccineDose(doseType);
            setAgeGroup(ageGroupSelected);
            console.log(ageGroup + "GGGGGGGGGGGG" + selectedAge + "SSSSSSSSSS" + ageGroupList)

            if(editMode){
                const vaccineCenterEdit = moh.districtFullListLocationDto.filter(res => Number.parseInt(res.locationId) === selectedDataRow.locationDetailsResDto[0].lid)[0];
                setMinVaccineCount(Number.parseInt(cancelledSession.vaccinesCount) - 1 + selectedDataRow.getAppoinments);
                setSessionVaccines(Number.parseInt(cancelledSession.vaccinesCount) + selectedDataRow.getAppoinments);
                setVaccinationCenter(vaccineCenterEdit);
                setAgeGroup(ageGroupSelected);
            }else {
                setMinVaccineCount(Number.parseInt(cancelledSession.vaccinesCount) - 1);
                setSessionVaccines(cancelledSession.vaccinesCount);
            }
        }

        /** isAbsorb function end */

    const sessionCreated = confirmation.payload.error_msg === 'Session Created Successfully';
    const sessionUpdated = confirmation.payload.error_msg === 'Session Updated Successfully';
    const notEdit = !(sessionCreated || sessionUpdated);
    const sessionDeleted = notEdit ? confirmation.payload.error_msg === 'Session delete successfully.' : false;

    const confirmed = sessionCreated || sessionUpdated || sessionDeleted || error;

    return (
        <div>
            <Dialog
                open={openAlert}
                onClose={handleCloseAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className={classes.confirmationDialog}>
                    Are you sure you want to delete this session ?
                    <div className={classes.confirmationActions}>
                        <Button
                            onClick={handleRemoveSession}
                            className={classes.deleteConfirmBtn}
                        >
                            Yes
                        </Button>
                        <Button onClick={handleCloseAlert}> No </Button>
                    </div>
                </div>
            </Dialog>

            {/** Create Session Button */}
            <Button
                className={classes.createSession}
                onClick={handleClickOpen}
                variant="text"
                color="primary"
                endIcon={<AddIcon />}
            >
                <Typography
                    component="h3"
                    variant="h6"
                    align="left"
                    className={classes.mainFont}
                >
                    Create Session
                </Typography>
            </Button>
            <Dialog
                fullWidth
                maxWidth="sm"
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                disableBackdropClick
                className={classes.model}
                PaperProps={{
                    style: {
                        borderRadius: '20px',
                    },
                }}
                PaperComponent={PaperComponent}
            >
                 <div className={classes.titleRow}>
                    <DialogTitle
                        style={{ cursor: 'move' }}
                        id="session-create-dialog-title"
                        onClose={handleClose}
                    >
                        {' '}
                        {!editMode ? 'Create Session' : 'Update Session'}
                    </DialogTitle>
                    <div>
                        {editMode && !loading ? (
                            <Button
                                onClick={handleOpenAlert}
                                variant="text"
                                color="primary"
                                endIcon={<DeleteIcon />}
                                className={classes.deleteIcon}
                                disabled={loading || !canDelete}
                            >
                                Remove this session
                            </Button>
                        ) : null}
                    </div>
                </div>

                <DialogContent dividers>
                    {error ? (
                        <Alert severity="error">{error}</Alert>
                    ) : sessionUpdated && editMode? (
                        <Alert severity="success">Session updated.</Alert>
                    ) : sessionCreated ? (
                        <Alert severity="success">New session created.</Alert>
                    ) : sessionDeleted ? (
                        <Alert severity="success">Session removed.</Alert>
                    ) : null}

                    {!confirmed ? (
                        <div>
                            {/** Is Absorb */}
                            <div className={classes.inputLabel}>
                                Absorb cancelled session
                            </div>
                            <Checkbox
                                checked={isAbsorb}
                                onChange={(e) => {handleChangeIsAbsorbCheckbox(e)}}
                                name="checkedB"
                                color="primary"
                            />
                            <br/>
                            <br/>  
                            {/** isAbsorb cancel sessions */}
                            {isAbsorb ? 
                                <React.Fragment>
                                    <div className={classes.inputLabel}>
                                        Cancelled sessions
                                    </div>
                                    <Autocomplete
                                        className={classes.inputFeild}
                                        id="cancelledSession"
                                        value={cancelledSession}
                                        options={editMode ? (cancelledSessionList?.data && cancelledSessionList.data.filter(
                                                res => (res.districtId === district.districtId && res.mohId === mohCenter.mohId && res.doseType === vaccineDose.id && res.vaccineType === vaccineType.id && (res.minage + ' - ' + (res.maxage === 150 ? 'Above': res.maxage) === ageGroup.group))
                                            )) : cancelledSessionList.data}

                                        getOptionLabel={option => option ? 
                                            `${districtsList?.filter(res => option?.districtId === res?.districtId)[0].districtName}` +' - ' + 
                                                        `${districtsList?.filter(res => option?.districtId === res?.districtId)[0].districtFullListMohDto?.filter(m => m?.mohId ===  option?.mohId)[0].mohName}` + ' - ' + 
                                                        `${option?.centerName} ` : '' 
                                            
                                        } 
                                       
                                        renderInput={params => (
                                            <TextField {...params} variant="outlined" />
                                        )}
                                        onChange={(event, newValue) => {
                                            handleChangeCancelledSession(event, newValue);
                                        }}
                                    />
                                    <br />
                                    <br />
                                </React.Fragment>
                                :
                                null
                            }  

                            {/**  Select District  */}
                            <div className={classes.inputLabel}>
                                District
                            </div>
                            <Autocomplete
                                className={classes.inputFeild}
                                id="district"
                                value={district}
                                disabled={isAbsorb}
                                options={districtsList}
                                getOptionLabel={option =>
                                    option?.districtName || ''
                                }
                                renderInput={params => (
                                    <TextField {...params} variant="outlined" />
                                )}
                                onChange={(event, newValue) => {
                                    handleDistrictChange(event, newValue);
                                }}
                            />
                            <br />
                            <br />

                            {/**  Select MOH Center  */}
                            <div className={classes.inputLabel}>
                                MOH Area
                            </div>
                            <Autocomplete
                                className={classes.inputFeild}
                                id="mohArea"
                                value={mohCenter}
                                options={mohCenterList}
                                disabled={isAbsorb}
                                getOptionLabel={option =>
                                    option?.mohName || ''
                                }
                                renderInput={params => (
                                    <TextField {...params} variant="outlined" />
                                )}
                                onChange={(event, newValue) => {
                                    handleMohCenterChange(event, newValue);
                                }}
                            />
                            <br />
                            <br />

                            {/**  Select Vaccination Center  */}
                            <div className={classes.inputLabel}>
                               Vaccination Center
                            </div>
                            <Autocomplete
                                className={classes.inputFeild}
                                id="vaccineCenter"
                                value={vaccinationCenter}
                                disabled={editMode && isAbsorb}
                                options={vaccinationCenterList}
                                getOptionLabel={option =>
                                    option?.locationName || ''
                                }
                                renderInput={params => (
                                    <TextField {...params} variant="outlined" />
                                )}
                                onChange={(event, newValue) => {
                                    handleVaccinationCenterChange(event, newValue);
                                }}
                            />
                            <br />
                            <br />

                            {/**  Select Date Time  */}
                            <div className={classes.inputrow}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        className={classes.datePicker}
                                        label="Session date"
                                        disableToolbar
                                        disabled={editMode && isAbsorb}
                                        variant="inline"
                                        format="dd-MM-yyyy"
                                        margin="normal"
                                        id="sessionDate"
                                        value={sessionDate || todayDate}
                                        keyboardbuttonprops={{
                                            'aria-label': 'change date',
                                        }}
                                        minDate={!editMode ? todayDate :sessionDate}
                                        autoOk={true}
                                        onChange={handleSessionDateChange}
                                    />

                                    <KeyboardTimePicker
                                        margin="normal"
                                        id="startTime"
                                        label="Start time"
                                        disabled={editMode && isAbsorb}
                                        value={sessionStartTime || new Date()}
                                        onChange={handleSessionStartTimeChange}
                                        className={classes.datePicker}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 0,
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />

                                    <KeyboardTimePicker
                                        margin="normal"
                                        id="endTime"
                                        label="End time"
                                        error={sessionEndTime < sessionStartTime}
                                        disabled={editMode && isAbsorb}
                                        helperText={
                                            sessionEndTime < sessionStartTime
                                                ? `Invalid time ("End time" should be later time than "Start time")`
                                                : ''
                                        }
                                        value={sessionEndTime || new Date()}
                                        onChange={handleSessionEndTimeChange}
                                        className={classes.datePicker}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 0,
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <br />
                            <br />

                            {/**  Select Vaccine Count  */}
                            <div className={classes.inputLabel}>
                                Total number of vaccines
                            </div>
                            <TextField
                                className={classes.inputFeild}
                                required
                                error={sessionVaccines < 0}
                                type="number"
                                id="vaccines"
                                variant="outlined"
                                disabled={editMode && isAbsorb}
                                onChange={handleSessionVaccinesChange}
                                value={sessionVaccines || ''}
                                InputProps={{
                                    inputProps: { min: 0, max: 100000 },
                                }}
                            />
                            <br />
                            <br />

                            {/** Age group */}
                            <div className={classes.inputLabel}>
                                Age Group
                            </div>
                            <Autocomplete
                                className={classes.inputFeild}
                                id="ageGroup"
                                value={ageGroup}
                                options={ageGroupList}
                                disabled={isAbsorb}
                                getOptionLabel={option =>
                                    option?.group || ''
                                }
                                renderInput={params => (
                                    <TextField {...params} variant="outlined" />
                                )}
                                onChange={(event, newValue) => {
                                    handleAgeGroupChange(event, newValue);
                                }}
                            />
                        
                            <br />
                            <br />
                            
                            {/**  Select Vaccine Name  */}
                            <div className={classes.inputLabel}>
                                Vaccine name
                            </div>
                            <Autocomplete
                                className={classes.inputFeild}
                                id="vaccineName"
                                value={vaccineType}
                                options={vaccineTypeList}
                                disabled={isAbsorb}
                                getOptionLabel={option =>
                                    option?.name || ''
                                }
                                renderInput={params => (
                                    <TextField {...params} variant="outlined" />
                                )}
                                onChange={(event, newValue) => {
                                    handleVaccineTypeChange(event, newValue);
                                }}
                            />
                            <br />
                            <br />

                            {/**  Select Vaccine Dose  */}
                            <div className={classes.inputLabel}>
                                Vaccination Dose
                            </div>
                            <Autocomplete
                                className={classes.inputFeild}
                                id="vaccineDose"
                                value={vaccineDose}
                                disabled={isAbsorb}
                                options={doses}
                                getOptionLabel={option =>
                                    option.name || ''
                                }
                                renderInput={params => (
                                    <TextField {...params} variant="outlined" />
                                )}
                                onChange={(event, newValue) => {
                                    handleVaccinationDoseChange(event, newValue);
                                }}
                            />

                            
                            {/**  Select Session Loacation Address  */}
                            {/* <div className={classes.inputLabel}>
                                Session location address
                            </div>
                            <TextField
                                className={classes.inputFeild}
                                //error={sessionVaccines < sessionAppoinments}
                                required
                                type="text"
                                id="vaccineLocation"
                                variant="outlined"
                                onChange={handleSessionVaccineLocationChange}
                                value={vaccineLocation || ''}
                                /* helperText={sessionVaccines < sessionAppoinments ?
                                    "Number of online allowed appoinments should be less than number of allowed vaccines" : ""} */
                           /* /> */}
                        </div>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    {confirmed ? (
                        <SubmitButton
                            className={classes.buttonStyle}
                            autoFocus
                            onClick={handleSessionFormReset}
                            color="primary"
                            variant="contained"
                        >
                            Ok
                        </SubmitButton>
                    ) : null}

                    {!confirmed ? (
                        !editMode ? (
                            <SubmitButton
                                className={classes.buttonStyle}
                                autoFocus
                                onClick={handleSessionCreateSubmission}
                                color="primary"
                                variant="contained"
                                disabled={loading || !isCreate}
                            >
                                {loading ? 'Creating...' : 'Create'}
                            </SubmitButton>
                        ) : (
                            <SubmitButton
                                className={classes.buttonStyle}
                                autoFocus
                                onClick={handleSessionUpdateSubmission}
                                color="primary"
                                variant="contained"
                                disabled={(loading || !isSessionUpdate || sessionStatus === 'R')}
                            >
                                {loading ? 'loading...' : 'Update'}
                            </SubmitButton>
                        )
                    ) : null}
                </DialogActions>
            </Dialog>
        </div>
    );
};

function mapStateToProps({ session }) {
    let { error } = session?.confirmation;
    return {
        error,
        doses: session.doseList,
    };
}

export default connect(mapStateToProps, {
    submitFormSessionCreate,
    submitFormSessionUpdate,
    submitFormSessionRemove,
    resetSessionCreateForm,
    fetchCancelledSessions,
})(SessionAdd);
