import { call, all, put, takeLatest } from 'redux-saga/effects';

import sessionApi from '../services/sessionApi';

import {
    FETCH_CENTERS,
    SUBMIT_FORM_SESSION,
    RESET_FORM_SESSION,
    FETCH_SESSIONS,
    UPDATE_FORM_SESSION,
    DELETE_FORM_SESSION, 
    FETCH_DISTRICT,
    NOTIFY_ALL,
    CANCEL_SESSION,
    FETCH_CANCELLED_SESSIONS,
    FETCH_VACCINE_TYPE,
} from '../actions/types';
import {
    fetchCentersFailed,
    fetchCentersSuccess,
    fetchDistrictFailed,
    fetchDistrictSuccess,
    fetchSessionsSuccess,
    fetchSessionsFailed,
    submitFormSessionCreateSuccess,
    submitFormSessionCreateFailed,
    restFormSessionSuccess,
    fetchSessions,
    submitFormSessionUpdateSuccess,
    submitFormSessionUpdateFailed,
    deleteFormSessionSuccess,
    deleteFormSessionFailed,
    notifyAllSuccess,
    notifyAllFailed,
    cancelSessionSuccess,
    cancelSessionFailed,
    fetchCancelledSessions,
    fetchCancelledSessionsSuccess,
    fetchCancelledSessionsFailed,
    fetchVaccineTypsSuccess,
    fetchVaccineTypsFailed,
} from 'actions/session';

function* fetchCenters() {
    try {
        const data = yield call(sessionApi.centers.getCenters);
        yield put(fetchCentersSuccess(data));
    } catch (err) {
        yield put(fetchCentersFailed(err));
    }
}

function* notifyAllUsers({ payload }) {
    try {
        const data = yield call(sessionApi.notify.notifyAll, payload);
        if(data?.status){
            yield put(notifyAllSuccess(data));
        }else{
            yield put(notifyAllFailed(data));
        }
         
    } catch (err) {
        let data = {
            status : false,
            error_msg: "Fail to send SMS",
            error_code: "",
            timestamp : "",
        }
        yield put(notifyAllFailed(data));
    }
}

/** Fetch Disctricts master data */
function* fetchDistricts() {
    try {
        const data = yield call(sessionApi.districts.getDistricts);
        if(data.status){
            yield put(fetchDistrictSuccess(data));
        }else {
            yield put(fetchDistrictFailed(data));
        }
    } catch (err) {
        let data = {
            "data": [],
            "status": false,
            "error_msg": "",
            "timestamp": "2021-06-11 15:29:52.196",
            "error_code": null
        }
        yield put(fetchDistrictFailed(data));
    }
}

function* fetchSessionsData({payload}) {
    try {
        const data = yield call(sessionApi.sessions.getAll,payload);
        if(data.status){
            yield put(fetchSessionsSuccess(data));
        }else{
            yield put(fetchSessionsFailed(data));
        }
    } catch (err) {
        let data = {
            "data": [],
            "status": true,
            "error_msg": null,
            "timestamp": "2021-06-13 09:54:38.022",
            "error_code": null
        }
        yield put(fetchSessionsFailed(data));
    }
}

function* submitFormSession({ payload }) {
    try {
        const data = yield call(sessionApi.sessions.submit, payload);
        if(data.status){
            yield put(fetchSessions());
            yield put(submitFormSessionCreateSuccess(data));
        }else{
            //data['error_msg'] = "Error occurred when creating the session."
            yield put(submitFormSessionCreateFailed(data));
        }
    } catch (err) {
        let data = {error_msg: "Error occurred when creating the session."}
        yield put(submitFormSessionCreateFailed(data));
    }
}

function* updateSessionForm({ payload }) {
    try {
        let data = yield call(sessionApi.sessions.update, payload);
        if(data.status){
            yield put(fetchSessions());
            yield put(submitFormSessionUpdateSuccess(data));
        }else{
            yield put(submitFormSessionUpdateFailed(data));
        }
    } catch (err) {
        let data = {error_msg: "Error occurred when updating the session."}
        yield put(submitFormSessionUpdateFailed(data));
    }
}

function* deleteSessionFormData({ payload }) {
    try {
        const data = yield call(sessionApi.sessions.delete, payload);
        if(data.status){
            yield put(fetchSessions());
            data['error_msg'] = 'Session delete successfully.'
            yield put(deleteFormSessionSuccess(data));
        }else{
            let data = {
                "data": [],
                "status": false,
                "error_msg": "The session delete failed.",
                "timestamp": "2021-06-05 12:28:06.465",
                "error_code": null
            } 
            yield put(deleteFormSessionFailed(data));
        }
    } catch (err) {
        let data = {
            "data": [],
            "status": false,
            "error_msg": "The session delete failed.",
            "timestamp": "2021-06-05 12:28:06.465",
            "error_code": null
        } 
        yield put(deleteFormSessionFailed(data));
    }
}

function* resetSessionForm() {
    //yield put(fetchCancelledSessions());
    yield put(restFormSessionSuccess());
}

function* cancelSessions({payload}) {
    try {
        const data = yield call(sessionApi.cancel.cancelSessions,payload);
        if(data?.status){
            yield put(cancelSessionSuccess(data));
        }else{
            yield put(cancelSessionFailed(data));
        }
    } catch (err) {
        let data = {
            "data": [],
            "status": false,
            "error_msg": "The session cancellation failed.",
            "timestamp": "2021-06-05 12:28:06.465",
            "error_code": null
        }
        yield put(cancelSessionFailed(data));
    }
}

function* fetchCancelledSessionsList() {
    try {
        let data = yield call(sessionApi.cancel.getCancelledSessionList);
        if(data?.status){
            yield put(fetchCancelledSessionsSuccess(data));
        }else{
            yield put(fetchCancelledSessionsFailed(data));
        }
    } catch (err) {
        let data = {
            "data": [],
            "status": false,
            "error_msg": null,
            "timestamp": "2021-06-15 14:55:58.892",
            "error_code": null
        }
        yield put(fetchCancelledSessionsFailed(data));
    }
}

function* fetchVaccineTypes() {
    try {
        const data = yield call(sessionApi.vaccineType.vaccineType);
        if(data?.status){
            yield put(fetchVaccineTypsSuccess(data));
        }else{
            yield put(fetchVaccineTypsFailed(data));
        }
    } catch (err) {
        let data = {
            "data": {},
            "status": false,
            "error_msg": null,
            "timestamp": "2021-06-05 12:28:06.465",
            "error_code": null
        }
        yield put(fetchVaccineTypsFailed(data));
    }
}

function* watchSessionActions() {
    // takeevery
    yield takeLatest(FETCH_CENTERS, fetchCenters);
    yield takeLatest(FETCH_DISTRICT, fetchDistricts);
    yield takeLatest(FETCH_SESSIONS, fetchSessionsData);
    yield takeLatest(SUBMIT_FORM_SESSION, submitFormSession);
    yield takeLatest(RESET_FORM_SESSION, resetSessionForm);
    yield takeLatest(UPDATE_FORM_SESSION, updateSessionForm);
    yield takeLatest(DELETE_FORM_SESSION, deleteSessionFormData);
    yield takeLatest(NOTIFY_ALL, notifyAllUsers);
    yield takeLatest(CANCEL_SESSION, cancelSessions);
    yield takeLatest(FETCH_CANCELLED_SESSIONS, fetchCancelledSessionsList);
    yield takeLatest(FETCH_VACCINE_TYPE, fetchVaccineTypes);
}

export default function* SessionSaga() {
    yield all([watchSessionActions()]);
}
