import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ErrorDisplay from 'views/Common/ErrorDisplay';
import Spinner from 'views/Common/Spinner';
import SessionAdd from './SessionAdd';
import {
    fetchDistricts,
    submitFormSessionCreate,
    fetchSessions,
    resetSendAllNotification,
    cancelSession,
    cancelSessionReset,
    fetchVaccineTyps,
    fetchCancelledSessions,
    notifyAll,
} from 'actions/session';
import { Redirect } from 'react-router-dom';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {Button, colors, TextareaAutosize, TextField} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
    root: {
        '& .table--cell': {
            fontSize: '13px',
        },
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: '85%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
        borderRadius: '20px',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    mainFont: {
        color: '#004F8B',
        'font-weight': 1000,
    },
    textBox: {
        padding: '10px',
    },
    subtitle: {
        color: '#848484',
    },
    tableHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    tableHeaderTitle: {
        display: 'flex',
        gap: '20px',
    },
    tableCell: {
        minWidth: 'fit-content',
    },
    confirmationDialog: {
        padding: '20px',
    },
    confirmationActions: {
        paddingTop: '20px',
        display: 'flex',
        justifyContent: 'center',
    },
    deleteConfirmBtn: {
        color: 'red',
    },
    cancellationMsg: {
        width: '100%',
        cursor: 'auto',
        padding: '5px',
    },
    filterRow: {
        display: 'flex',
        alignItems: 'flex-end',
    },
}));

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
}))(MuiDialogActions);

const SubmitButton = withStyles(theme => ({
    root: {
        backgroundColor: '#004F8B',
        width: '100%',
        '&:hover': {
            backgroundColor: '#004F8B',
        },
        height: '50px',
    },
}))(Button);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e6e6e6',
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 15,
    },
}))(TableCell);

const Sessions = ({
    loading,
    error,
    data,
    sessionsList,
    fetchDistricts,
    confirmation,
    fetchSessions,
    loginSuccess,
    districtsList,
    notifyResponse,
   // disabledNotifyButton,
    disabledNotify,
    isOpenAlertMsgProp,
    resetSendAllNotification,
    cancelSession,
    cancelledSessionList,
    cancelResponse,
    cancelSessionReset,
    vaccineTypeList,
    fetchVaccineTyps,
    fetchCancelledSessions,
    notifyAll,
    ageGroupList,
}) => {
    useEffect(() => {
        fetchDistricts();
        fetchVaccineTyps();
        fetchSessionsList(fromDate, toDate, districtFilter);        
        setWindowsDimentions();
        fetchCancelledSessions();
       // changeNotifyButton(sessionsList);
    }, [sessionStorage.getItem('token'),fetchDistricts, fetchSessions,resetSendAllNotification, fetchVaccineTyps, fetchCancelledSessions]);

    const [localSessionListState, setLocalSessionListState] = React.useState([]);

    useEffect(() => {
        if(sessionsList?.length > 0 ){
            setLocalSessionListState(sessionsList);
        }
    },[sessionsList,localSessionListState])

    const reLoadData = () => {
        fetchDistricts();
        fetchVaccineTyps();
        fetchSessionsList(fromDate, toDate, districtFilter);
        setSelection(null);
        fetchCancelledSessions();
    };

    const fetchSessionsList = (from , to , dObj) => {
        let distric = 0;
        let status = 'N';
        let selectedFromDate = '';
        let selectedToDate = '';

        if(dObj !== ''){
            distric = dObj.districtId;
            status = 'Y';
        }
        if(from !== '' && to !== ''){
            status = 'Y';
            selectedFromDate = from;
            selectedToDate = to;
        }
        if(dObj === '' && from === '' && to === ''){
            status = 'N';
            distric = 0;
        }
        
        fetchSessions({
            districtId: distric, 
            status: status,
            fromDate: selectedFromDate.toString(),
            toDate: selectedToDate.toString(),
        });
    }

    const cancelMsgRegex = new RegExp("^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$");
    const lastWhiteSpaceRegex = new RegExp("\s+$");

    const classes = useStyles();
    const [openAlert, setOpenAlert] = React.useState(false);
    const [notifyAllObj,setNotifyAllObj] = React.useState(false);
    const [select, setSelection] = React.useState(null);
    const [objectIndex, setObjectIndex] = React.useState([]);
    const [warningAlertMessage, setWarningAlertMessage] = React.useState('');
    const [isCancel, setIsCancel] = React.useState(null);
    const FromDateMin = new Date('2020-01-01');
    const [toDateMin, setToDateMin] = useState(new Date(FromDateMin));
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [validationMessage, setValidationMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [cancellationMsg, setCancellationMsg] = React.useState('');
    const [districtFilter, setDistrictFilter] = React.useState('');

    const handleChangeCancellationMsg = event => {
        let tempMsg = event.target.value;
        /* if(tempMsg !== null && !lastWhiteSpaceRegex.test(tempMsg)){
            tempMsg = tempMsg.slice(0, -1);
        } */
        setCancellationMsg(tempMsg);
    }

    const handleFromDateDateChange = event => {
        let tmpDate = new Date(event);
        tmpDate = moment(tmpDate).format(moment.HTML5_FMT.DATE);
        setFromDate(tmpDate);
        setToDate(tmpDate);
        setToDateMin(tmpDate);
    }

    const handleToDateChange = event => {
        if(fromDate === ''){
            setValidationMessage("Please Select Start Date.")
            handleClickOpen();
        }else{
            let tmpDate = new Date(event);
            tmpDate = moment(tmpDate).format(moment.HTML5_FMT.DATE);
            setToDate(tmpDate);
            fetchSessionsList(fromDate, tmpDate, districtFilter);
        } 
    }

    const handleResetDateFilter = () => {
        setFromDate('');
        setToDate('');
        setDistrictFilter('');
        fetchSessionsList('','','');
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    /* const changeNotifyButton = (id, index) => {
        let array = localSessionListState;
        let selectedObj = array.filter(obj => obj.id === id)[0];
        selectedObj.isNotify = "Y";
        array.splice(index, 1, selectedObj);
    } */
    
    const handleOpenAlert = (session, index, type) => {
        if(type === 'isCancel'){
            setObjectIndex({
                id : session.sid,
                index: index,
                session : session,
            });
            setWarningAlertMessage("warning - Are you sure you want to cancel the session?");
            setIsCancel(true);
        }else {
            setNotifyAllObj({
                session_id: session.sid,	
                moh_codes :[ session.locationDetailsResDto[0].lid],
                session_date : moment(session.sdate).format('DD-MM-YYYY')
            });
            setWarningAlertMessage("warning - Are you sure you want to send notifications?");
            setIsCancel(false);
        }
        setSelection(null);
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
        setIsCancel(null);
        setCancellationMsg('');
        fetchSessions();
    };

    /* const handleSendNotification = () => {
        changeNotifyButton(
            objectIndex.id,objectIndex.index
        ); 
        //fetchSessions();
        handleCloseAlert();
        //disabledNotifyButton(true);
    } */

    const handleCancelSession = () => {
        cancelSession({
            session_id: objectIndex.id,
            msg: cancellationMsg,
        });
        handleCloseAlert();
    }

    function getSessionTime(time) {
        let hour = '';
        let minuite = '';
        let suffix = '';

        if (time) {
            let hourString = Number(time.split(':')[0]);
            hour = hourString % 12 || 12;
            minuite = time.split(':')[1];
            suffix = hourString >= 12 ? 'PM' : 'AM';
        }

        return `${hour}:${minuite} ${suffix}`;
    }

    function getToTime(rowData) {
        return getSessionTime(rowData?.gettoTime);
    }

    function getFromTime(rowData) {
        return getSessionTime(rowData?.fromTime);
    }

    //const [expanded, setExpanded] = React.useState('panel0');

    /* const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }; */

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

   /*  const handleUpdate = e => {
        alert('dsds');
    }; */

    const handleSessionSelection = (selectedSession, ramdomId) => {
        let obj = {
            ramdomId: ramdomId,
            select: selectedSession,
        };
        setSelection(obj);
        resetSendAllNotification();
        //fetchSessions();
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [isMobile, setIsMobile] = React.useState(false);

    window.addEventListener('resize', function() {
        window.innerWidth < 600 ? setIsMobile(true) : setIsMobile(false);
    });

    window.addEventListener('load', function() {
        window.innerWidth < 600 ? setIsMobile(true) : setIsMobile(false);
    });

    function setWindowsDimentions() {
        window.innerWidth < 600 ? setIsMobile(true) : setIsMobile(false);
    }

    const getMohCodes = mohEntity => {
        let codeArray = [];
        for(let i = 0 ; i< mohEntity.length; i++){
            codeArray.push(mohEntity[i].mohCode);
        }
        return codeArray;
    }

    const closeAlertMessage = () =>{
        resetSendAllNotification();
        cancelSessionReset();
        fetchSessions();
        updateCancelledSessionList();
    }

    const handleNotifyAbsorbedSessions = () => {
        notifyAll(notifyAllObj);
        fetchSessions();
        handleCloseAlert();
    }

    const updateCancelledSessionList = () => {
        fetchCancelledSessions();
        setSelection(null);
    }

    const handleDistrictFilterChange = (event,value) => {
        setDistrictFilter('');
        if(value !== null){
            setDistrictFilter(value);
            fetchSessionsList(fromDate, toDate , value)
        }
    }

    return !loginSuccess ? (
        <Redirect to="/sign-in" />
    ) : loading ? (
        <Spinner></Spinner>
    ) : error ? (
        <ErrorDisplay error={error} refreshButton={true}></ErrorDisplay>
    ) : (
        <div className={classes.root}>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    {isMobile === false ? (
                        ''
                    ) : (
                        <SessionAdd
                            seletedRowData={select}
                            setSelection={setSelection}
                            centerData={data}
                            districtsList={districtsList}
                            confirmation={confirmation}
                            loading={loading}
                            cancelledSessionList={cancelledSessionList}
                            vaccineTypeList={vaccineTypeList}
                            updateCancelledSessionList={updateCancelledSessionList}
                            ageGroupList={ageGroupList}
                        />
                    )}
                    <div className={classes.tableHeader}>
                        <div className={classes.tableHeaderTitle}>
                            <Typography
                                component="h1"
                                variant="h2"
                                align="left"
                                className={classes.mainFont}
                            >
                                Vaccination sessions
                            </Typography>
                            <Typography
                                component="h1"
                                variant="h2"
                                align="left"
                                className={classes.mainFont}
                            >
                                <i
                                    className="fa fa-refresh"
                                    onClick={() => {
                                        reLoadData();
                                    }}
                                ></i>
                            </Typography>
                        </div>
                        {isMobile === true ? (
                            ''
                        ) : (
                            <SessionAdd
                                seletedRowData={select}
                                setSelection={setSelection}
                               // centerData={data}
                                districtsList={districtsList}
                                confirmation={confirmation}
                                loading={loading}
                                cancelledSessionList={cancelledSessionList}
                                vaccineTypeList={vaccineTypeList}
                                updateCancelledSessionList={updateCancelledSessionList}
                                ageGroupList={ageGroupList}
                            />
                        )}
                    </div>
                    <br />
                    <br />
                    <div>
                        <Autocomplete
                            //className={classes.inputFeild}
                            id="district"
                            value={districtFilter}
                            options={districtsList}
                            getOptionLabel={option =>
                                option?.districtName || ''
                            }
                            renderInput={params => (
                                <TextField 
                                    {...params} 
                                    variant="outlined"
                                    label="Select the district"
                                 />
                            )}
                            onChange={(event, newValue) => {
                                handleDistrictFilterChange(event, newValue);
                            }}
                        />
                    </div>
                    <div className={classes.filterRow}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className={classes.datePicker}
                                label="Session Date From"
                                disableToolbar
                                variant="inline"
                                format="dd-MM-yyyy"
                                margin="normal"
                                id="sessionDateFrom"
                                value={fromDate || null}
                                keyboardbuttonprops={{
                                    'aria-label': 'change date',
                                }}
                                minDate={FromDateMin}
                                autoOk={true}
                                onChange={handleFromDateDateChange}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <KeyboardDatePicker
                                className={classes.datePicker}
                                label="Session Date To"
                                disableToolbar
                                variant="inline"
                                format="dd-MM-yyyy"
                                margin="normal"
                                id="sessionDateTo"
                                value={toDate || null}
                                keyboardbuttonprops={{
                                    'aria-label': 'change date',
                                }}
                                minDate={toDateMin}
                                autoOk={true}
                                onChange={handleToDateChange}
                            />
                        </MuiPickersUtilsProvider>
                        <Button onClick={handleResetDateFilter}>Clear Filter</Button>
                    </div>
                    <br/>
                    <br/>
                    <div
                        style={{ height: '100%', width: '100%' }}
                        className={classes.root}
                    >
                        
                        {sessionsList?.length === 0 ? (
                            <Typography
                                component="h6"
                                variant="h6"
                                align="center"
                            >
                                No Data
                            </Typography>
                        ) : (
                            <React.Fragment>
                                <TableContainer component={Paper}>
                                    <Table
                                        className={classes.table}
                                        aria-label="customized table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell width={"10%"}>
                                                    SESSION DATE
                                                </StyledTableCell>
                                                <StyledTableCell width={"10%"}>
                                                    DISTRICT
                                                </StyledTableCell>
                                                <StyledTableCell width={"10%"}>
                                                    MOH AREA
                                                </StyledTableCell>
                                                <StyledTableCell width={"10%"}>
                                                    VACCINE CENTERS
                                                </StyledTableCell>
                                                {/* <StyledTableCell width={"10%"}>
                                                    START TIME
                                                </StyledTableCell>
                                                <StyledTableCell width={"10%"}>
                                                    END TIME
                                                </StyledTableCell> */}
                                                <StyledTableCell width={"10%"}>
                                                    VACCINES
                                                </StyledTableCell>
                                                <StyledTableCell width={"10%"}>
                                                    VACCINE TYPE
                                                </StyledTableCell>
                                                <StyledTableCell width={"10%"}>
                                                    VACCINATION DOSE
                                                </StyledTableCell>
                                                <StyledTableCell width={"10%"}>
                                                    AGE GROUP
                                                </StyledTableCell>
                                                <StyledTableCell width={"20%"}>
                                                    SESSION CODES
                                                </StyledTableCell>
                                                <StyledTableCell width={"10%"}>
                                                    ACTION
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sessionsList?.slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage +
                                                        rowsPerPage
                                                )
                                                .map((session,index) => (
                                                    <StyledTableRow
                                                        key={session.sid}
                                                        /* onClick={() => {
                                                            handleSessionSelection(session);
                                                        }} */
                                                    >
                                                        <StyledTableCell
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            {session.sdate}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {
                                                                session?.districtRespDto?.districtName
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {
                                                                session?.mohRespDto?.mohName
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {session.locationDetailsResDto?.map(
                                                                (val, ind) => {
                                                                    return (
                                                                        <div key={ind} > {val.locationName}</div>
                                                                    );
                                                                }
                                                            )
                                                            //getSessionCode(session)
                                                            }
                                                        </StyledTableCell>
                                                        {/* <StyledTableCell>
                                                            {getFromTime(session)}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {getToTime(session)}
                                                        </StyledTableCell> */}
                                                        <StyledTableCell
                                                            style={{textAlign:'center',}}
                                                        >
                                                            {session.getAppoinments}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {session?.vaccineRespDto?.vaccineName}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {session.doseType}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {
                                                                ageGroupList.filter(res => res.group === (session.minage + ' - ' + (session.maxage === 150 ? 'Above': session.maxage)))[0].group
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {session.sessionhoursresDto?.map(
                                                                (val, ind) => {
                                                                    return (
                                                                        <div key={ ind } >
                                                                            <ul>
                                                                                <li>{getSessionTime(val.sesssionStart) + ' - ' + getSessionTime(val.sessionEnd ) + ' : ' }{val.subSession}</li>
                                                                            </ul>
                                                                        </div>
                                                                    );
                                                                }
                                                            )
                                                            //getSessionCode(session)
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {session.sessionStatus === 'C' ? <React.Fragment><p>CANCELLED</p><div><p>Reason : </p><p>{session.cancellationReason}</p></div></React.Fragment> : 
                                                                session.sessionStatus === 'R' ? /* <React.Fragment><p>ABSORBED</p></React.Fragment> */ null: 
                                                                session.sessionStatus === 'A' ? <React.Fragment><p>SCHEDULED</p></React.Fragment> : 
                                                                session.sessionStatus === 'Y' && session.expired === true ? <React.Fragment><p>EXPIRED</p></React.Fragment> : null
                                                            }
                                                            {session.sessionStatus !== 'C' &&  /* session.sessionStatus !== 'A' && */ !(session.sessionStatus === 'Y' && session.expired === true) ? 
                                                                <React.Fragment>
                                                                    {session.sessionStatus === 'R' ? <Button onClick={(e) => { handleOpenAlert(session, index, "isNotify")}}>Notify</Button> : null }
                                                                    <Button onClick={(e) => { handleSessionSelection(session)}}>Edit</Button>
                                                                        {!session.canDelete && session.sessionStatus !== 'R' ?  
                                                                            <Button onClick={e =>{handleOpenAlert(session, index, "isCancel")}}  style={{color: "red"}} >Cancel</Button>: null}
                                                                </React.Fragment> : null

                                                            }
                                                            {/* {session.sessionStatus === 'C' ? <React.Fragment><p>CANCELLED</p><div><p>Reason : </p><p>{session.cancellationReason}</p></div></React.Fragment> : 
                                                                session.sessionStatus === 'A' ? <React.Fragment><p>ABSORBED</p> <Button onClick={(e) => { handleOpenAlert(session, index, "isNotify")}}>Notify</Button></React.Fragment> :
                                                                <React.Fragment>
                                                                    <Button onClick={(e) => { handleSessionSelection(session)}}>Edit</Button>
                                                                    {!session.canDelete ?  
                                                                        <Button onClick={e =>{handleOpenAlert(session, index, "isCancel")}}  style={{color: "red"}} >Cancel</Button>
                                                                        : null}
                                                                </React.Fragment>
                                                            } */}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 50]}
                                    component="div"
                                    count={sessionsList?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                    }
                                />
                            </React.Fragment>
                        )}
                    </div>
                    {/** Notify and cancel confirmation alert */}
                    <Dialog
                        open={openAlert}
                        onClose={handleCloseAlert}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        {/*<div className={classes.confirmationDialog}>*/}
                            <DialogTitle id="alert-dialog-title">
                                <Alert variant="filled" severity="warning">
                                    {warningAlertMessage}
                                </Alert>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <span style={{color: "red"}}>IMPORTANT : </span> After you click <b>YES</b> you won't be able to revert back your action.
                                </DialogContentText>

                                {isCancel ? 
                                    <React.Fragment>
                                        <TextareaAutosize 
                                            className={classes.cancellationMsg}
                                            id="cancellationText"
                                            aria-label="minimum height" 
                                            rowsMin={4} 
                                            placeholder="Cancellation Reason"
                                            value={cancellationMsg || ''}
                                            onChange={handleChangeCancellationMsg}
                                            inputProps={{ maxLength: 30 }}
                                        /> 
                                        <p>*Max character length is 30 and does not allow special characters.</p>
                                    </React.Fragment>
                                    : null    
                                }
                            </DialogContent>
                            <DialogActions>
                            { isCancel && cancellationMsg !== '' && cancellationMsg.split(" ").join("").length > 0 && cancelMsgRegex.test(cancellationMsg) && cancellationMsg.length < 31? 
                                <Button
                                    onClick={handleCancelSession}
                                    className={classes.deleteConfirmBtn}
                                >
                                    Yes
                                </Button> 
                                : !isCancel ? 
                                <Button
                                    onClick={handleNotifyAbsorbedSessions}
                                    className={classes.deleteConfirmBtn}
                                >
                                Yes
                                </Button> 
                                : null
                            }
                                <Button onClick={handleCloseAlert}> No </Button>
                            </DialogActions>
                    </Dialog>

                    {/* ************ Notify and Cancel success/error Alert Box ************* */}
                    
                    <Dialog
                        fullWidth
                        maxWidth="sm"
                        onClose={closeAlertMessage}
                        aria-labelledby="customized-dialog-title"
                        open={isOpenAlertMsgProp}
                        className={classes.model}
                        PaperProps={{
                            style: {
                                borderRadius: '20px',
                            },
                        }}
                    //PaperComponent={PaperComponent}
                    >

                        <DialogContent dividers>
                            {
                                notifyResponse.hasOwnProperty('status') ? (
                                    notifyResponse.status ? 
                                        <Alert severity="success">{notifyResponse?.error_msg}</Alert> 
                                        :
                                        <Alert severity="error">{notifyResponse?.error_msg}</Alert>
                                ) : null
                                   
                            }
                            {
                                cancelResponse.hasOwnProperty('status') ? (
                                    cancelResponse.status ?
                                        <Alert severity="success">{cancelResponse?.error_msg}</Alert> 
                                        :
                                        <Alert severity="error">{cancelResponse?.error_msg}</Alert>
                                ) : null 
                                    
                            }
                        </DialogContent>
                        <DialogActions>
                            <SubmitButton
                                className={classes.buttonStyle}
                                autoFocus
                                onClick={closeAlertMessage}
                                color="primary"
                                variant="contained"
                            >
                            OK
                            </SubmitButton>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </main>

            {/** Date Validation Dialog */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Error
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                       {validationMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                       Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

//  mention the reducer
function mapStateToProps({ session, signin }) {
    // what inside the reducer
    let loginSuccess = sessionStorage.getItem('loginSuccess');
    let { centers, confirmation, sessions, districts, disabledNotifyButton, notify, loading, cancelledSessionList, cancelSession, vaccineTypes, ageGroupList} = session;
    let vaccineTypeList = vaccineTypes.data;
    // define attr inside the objs
    return {
        loginSuccess,
        loading: /* centers.loading || */ sessions.loading || loading,
        error: centers.error,
        data: centers.data,
        confirmation: confirmation,
        sessionsList: sessions.data,
        districtsList: districts.data,
        notifyResponse : notify.data,
        disabledNotify : disabledNotifyButton,
        isOpenAlertMsgProp : notify?.loading || cancelSession?.loading ? true : false,
        cancelledSessionList,
        cancelResponse : cancelSession.data,
        vaccineTypeList,
        ageGroupList,
    };
}

// bind the actions here in a object
export default connect(mapStateToProps, {
    submitFormSessionCreate,
    fetchDistricts,
    fetchVaccineTyps,
    fetchSessions,
    resetSendAllNotification,
    cancelSession,
    cancelSessionReset,
    fetchCancelledSessions,
    notifyAll,
    //disabledNotifyButton,
})(Sessions);
