import {
    FETCH_CENTERS,
    FETCH_CENTERS_SUCCESS,
    FETCH_CENTERS_FAILED,
    FETCH_DISTRICT,
    FETCH_DISTRICT_SUCCESS,
    FETCH_DISTRICT_FAILED,
    SUBMIT_FORM_SESSION,
    SUBMIT_FORM_SESSION_SUCCESS,
    SUBMIT_FORM_SESSION_FAILED,
    FETCH_SESSIONS,
    FETCH_SESSIONS_SUCCESS,
    FETCH_SESSIONS_FAILED,
    RESET_FORM_SESSION,
    RESET_FORM_SESSION_SUCCESS,
    UPDATE_FORM_SESSION,
    UPDATE_FORM_SESSION_SUCCESS,
    UPDATE_FORM_SESSION_FAILED,
    DELETE_FORM_SESSION,
    DELETE_FORM_SESSION_SUCCESS,
    DELETE_FORM_SESSION_FAILED,
    NOTIFY_ALL,
    NOTIFY_ALL_SUCCESS,
    NOTIFY_ALL_FAILED,
    DISABLED_NOTIFY_BUTTON,
    NOTIFY_ALL_RESET,
    CANCEL_SESSION,
    CANCEL_SESSION_SUCCESS,
    CANCEL_SESSION_FAILED,
    FETCH_CANCELLED_SESSIONS,
    FETCH_CANCELLED_SESSIONS_SUCCESS,
    FETCH_CANCELLED_SESSIONS_FAILED,
    CANCEL_SESSION_RESET,
    FETCH_VACCINE_TYPE,
    FETCH_VACCINE_TYPE_SUCCESS,
    FETCH_VACCINE_TYPE_FAILED,
} from './types';

export const resetSendAllNotification = payload => {
    return { type: NOTIFY_ALL_RESET, payload };
};

export const disabledNotifyButton = payload => {
    return { type: DISABLED_NOTIFY_BUTTON, payload };
};

export const fetchCenters = payload => {
    return { type: FETCH_CENTERS, payload };
};

export const fetchCentersSuccess = payload => {
    return { type: FETCH_CENTERS_SUCCESS, payload };
};

export const fetchCentersFailed = payload => {
    return { type: FETCH_CENTERS_FAILED, payload };
};

export const fetchDistricts = payload => {
    return { type: FETCH_DISTRICT, payload };
};

export const fetchDistrictSuccess = payload => {
    return { type: FETCH_DISTRICT_SUCCESS, payload };
};

export const fetchDistrictFailed = payload => {
    return { type: FETCH_DISTRICT_FAILED, payload };
};

export const fetchSessions = payload => {
    return { type: FETCH_SESSIONS, payload };
};

export const notifyAll = payload => {
    return { type: NOTIFY_ALL, payload };
};

export const notifyAllSuccess = payload => {
    return { type: NOTIFY_ALL_SUCCESS, payload };
};

export const notifyAllFailed = payload => {
    return { type: NOTIFY_ALL_FAILED, payload };
};

export const fetchSessionsSuccess = payload => {
    return { type: FETCH_SESSIONS_SUCCESS, payload };
};

export const fetchSessionsFailed = payload => {
    return { type: FETCH_SESSIONS_FAILED, payload };
};

// Create
export const submitFormSessionCreate = payload => {
    return { type: SUBMIT_FORM_SESSION, payload };
};

export const submitFormSessionCreateSuccess = payload => {
    return { type: SUBMIT_FORM_SESSION_SUCCESS, payload };
};

export const submitFormSessionCreateFailed = payload => {
    return { type: SUBMIT_FORM_SESSION_FAILED, payload };
};

// Reset
export const resetSessionCreateForm = payload => {
    return { type: RESET_FORM_SESSION, payload };
};

export const restFormSessionSuccess = payload => {
    return { type: RESET_FORM_SESSION_SUCCESS, payload };
};

// Update
export const submitFormSessionUpdate = payload => {
    return { type: UPDATE_FORM_SESSION, payload };
};

export const submitFormSessionUpdateSuccess = payload => {
    return { type: UPDATE_FORM_SESSION_SUCCESS, payload };
};

export const submitFormSessionUpdateFailed = payload => {
    return { type: UPDATE_FORM_SESSION_FAILED, payload };
};

// Delete
export const submitFormSessionRemove = payload => {
    return { type: DELETE_FORM_SESSION, payload };
};

export const deleteFormSessionSuccess = payload => {
    return { type: DELETE_FORM_SESSION_SUCCESS, payload };
};

export const deleteFormSessionFailed = payload => {
    return { type: DELETE_FORM_SESSION_FAILED, payload };
};

/** Cancel a Sessions */
export const cancelSession = payload => {
    return { type: CANCEL_SESSION, payload };
};

export const cancelSessionSuccess = payload => {
    return { type: CANCEL_SESSION_SUCCESS, payload };
};

export const cancelSessionFailed = payload => {
    return { type: CANCEL_SESSION_FAILED, payload };
};

export const cancelSessionReset = payload => {
    return { type: CANCEL_SESSION_RESET, payload };
};

/** Cancelled Sessions List */
export const fetchCancelledSessions = payload => {
    return { type: FETCH_CANCELLED_SESSIONS, payload };
};

export const fetchCancelledSessionsSuccess = payload => {
    return { type: FETCH_CANCELLED_SESSIONS_SUCCESS, payload };
};

export const fetchCancelledSessionsFailed = payload => {
    return { type: FETCH_CANCELLED_SESSIONS_FAILED, payload };
};

/** Fetch vaccine typs */
export const fetchVaccineTyps = payload => {
    return { type: FETCH_VACCINE_TYPE, payload };
};

export const fetchVaccineTypsSuccess = payload => {
    return { type: FETCH_VACCINE_TYPE_SUCCESS, payload };
};

export const fetchVaccineTypsFailed = payload => {
    return { type: FETCH_VACCINE_TYPE_FAILED, payload };
};