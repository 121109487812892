import {
    FETCH_CENTERS_SUCCESS,
    FETCH_CENTERS_FAILED,
    FETCH_DISTRICT_SUCCESS,
    FETCH_DISTRICT_FAILED,
    SUBMIT_FORM_SESSION_SUCCESS,
    SUBMIT_FORM_SESSION_FAILED,
    RESET_FORM_SESSION_SUCCESS,
    FETCH_SESSIONS_SUCCESS,
    FETCH_SESSIONS_FAILED,
    UPDATE_FORM_SESSION_SUCCESS,
    UPDATE_FORM_SESSION_FAILED,
    DELETE_FORM_SESSION_SUCCESS,
    DELETE_FORM_SESSION_FAILED,
    NOTIFY_ALL,
    NOTIFY_ALL_SUCCESS,
    NOTIFY_ALL_FAILED,
    DISABLED_NOTIFY_BUTTON,
    NOTIFY_ALL_RESET,
    CANCEL_SESSION,
    CANCEL_SESSION_SUCCESS,
    CANCEL_SESSION_FAILED,
    FETCH_CANCELLED_SESSIONS,
    FETCH_CANCELLED_SESSIONS_SUCCESS,
    FETCH_CANCELLED_SESSIONS_FAILED,
    CANCEL_SESSION_RESET,
    FETCH_VACCINE_TYPE,
    FETCH_VACCINE_TYPE_SUCCESS,
    FETCH_VACCINE_TYPE_FAILED,
} from '../actions/types';

const initialState = {
    centers: {
        loading: true,
        error: undefined,
        data: [],
    },
    districts: {
        loading: true,
        error: undefined,
        data: [],
    },
    sessions: {
        loading: true,
        error: undefined,
        data: [],
    },
    confirmation: {
        loading: false,
        error: undefined,
        payload: '',
    },
    notify:{
        loading :false,
        error: undefined,
        data:{}
    },
    disabledNotifyButton: {
        loading :false,
        error: undefined,
        isDisabled: false,
    },
    cancelSession: {
        loading :false,
        error: undefined,
        data: {},
    },
    cancelledSessionList :{
        loading :false,
        error: undefined,
        data: [],
    },
    vaccineTypes:{
        data: [],
        loading: false,
    },
    ageGroupList: [
        {group: '18 - 29'},
        {group: '20 - 30'},
        {group: '30 - 59'},
        {group: '30 - Above'},
        {group: '20 - Above'},
        {group: '60 - Above'},
    ],
    doseList: [
        {"id": "DOSE1","name": "DOSE1"},
        {"id": "DOSE2","name": "DOSE2"},
        {"id": "DOSE3","name": "BOOSTER DOSE"}
    ]
};

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CENTERS_SUCCESS:
            return {
                ...state,
                loading: false,
                centers: {
                    ...state.centers,
                    loading: false,
                    data: action.payload.organisationUnits,
                },
            };
        case FETCH_CENTERS_FAILED:
            return {
                ...state,
                centers: {
                    ...state.centers,
                    loading: false,
                    error:
                        'Error occurred when fetching the vaccination centers. Please refresh and try again',
                },
            };
        case FETCH_DISTRICT_SUCCESS:
            return {
                ...state,
                loading: false,
                districts: {
                    ...state.districts,
                    loading: false,
                    data: action.payload.data,
                },
            };
        case FETCH_DISTRICT_FAILED:
            return {
                ...state,
                districts: {
                    ...state.districts,
                    loading: false,
                    data: [],
                    error: 'Error occurred when fetching the Districts. Please refresh and try again',
                },
            };
        case FETCH_SESSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                sessions: {
                    ...state.sessions,
                    loading: false,
                    data: action.payload.data,
                    error: undefined,
                },
            };
        case FETCH_SESSIONS_FAILED:
            return {
                ...state,
                sessions: {
                    ...state.sessions,
                    loading: false,
                    error:
                        'Error occurred when fetching the sessions. Please refresh and try again',
                    data:[],
                },
            };
        case SUBMIT_FORM_SESSION_SUCCESS:
            return {
                ...state,
                loading: false,
                confirmation: {
                    ...state.confirmation,
                    loading: false,
                    payload: action.payload,
                    error: undefined,
                },
            };
        case SUBMIT_FORM_SESSION_FAILED:
            return {
                ...state,
                confirmation: {
                    ...state.confirmation,
                    loading: false,
                    error: action.payload.error_msg,
                    payload: action.payload,
                },
            };
        case RESET_FORM_SESSION_SUCCESS:
            return {
                ...state,
                confirmation: {
                    ...state.confirmation,
                    error:undefined,
                    loading: false,
                    payload: '',
                },
            };
        case UPDATE_FORM_SESSION_SUCCESS:
            return {
                ...state,
                loading: false,
                confirmation: {
                    ...state.confirmation,
                    loading: false,
                    payload: action.payload,
                    error: undefined,
                },
            };
        case UPDATE_FORM_SESSION_FAILED:
            return {
                ...state,
                confirmation: {
                    ...state.confirmation,
                    loading: false,
                    error: action.payload.error_msg,
                    payload: action.payload,
                },
            };
        case DELETE_FORM_SESSION_SUCCESS:
            return {
                ...state,
                loading: false,
                confirmation: {
                    ...state.confirmation,
                    loading: false,
                    payload: action.payload,
                },
            };
        case DELETE_FORM_SESSION_FAILED:
            return {
                ...state,
                confirmation: {
                    ...state.confirmation,
                    loading: false,
                    error:
                        'Error deleting the session. Please refresh and try again',
                },
            };

        case NOTIFY_ALL:
            return {
                ...state,
                loading :true,
            };
        case NOTIFY_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                notify: {
                    ...state.notify,
                    data : action.payload,
                    loading: true,
                }
            };
        case NOTIFY_ALL_FAILED:
            return {
                ...state,
                loading: false,
                notify: {
                    ...state.notify,
                    data : action.payload,
                    loading: true,
                }
        };
        case NOTIFY_ALL_RESET:
            return {
                ...state,
                loading: false,
                notify: {
                    ...state.notify,
                    data : {},
                    loading: false,
                }
        };
        case DISABLED_NOTIFY_BUTTON:
            return {
                ...state,
                disabledNotifyButton: {
                    ...state.disabledNotifyButton,
                    isDisabled : action.payload,
                    error : "",
                    loading: false,
                }
        };
        case CANCEL_SESSION:
            return {
                ...state,
                loading :true,
            };
        case CANCEL_SESSION_SUCCESS:
            return {
                ...state,
                loading: false,
                cancelSession: {
                    ...state.cancelSession,
                    data : action.payload,
                    loading: true,
                }
            };
        case CANCEL_SESSION_FAILED:
            return {
                ...state,
                loading: false,
                cancelSession: {
                    ...state.cancelSession,
                    data : action.payload,
                    loading: true,
                }
        };
        case CANCEL_SESSION_RESET:
            return {
                ...state,
                loading: false,
                cancelSession: {
                    ...state.cancelSession,
                    data : {},
                    loading: false,
                }
        };
        case FETCH_CANCELLED_SESSIONS:
            return {
                ...state,
                loading :true,
            };
        case FETCH_CANCELLED_SESSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                cancelledSessionList: {
                    ...state.cancelledSessionList,
                    data : action.payload.data,
                    loading: true,
                }
            };
        case FETCH_CANCELLED_SESSIONS_FAILED:
            return {
                ...state,
                loading: false,
                cancelledSessionList: {
                    ...state.cancelledSessionList,
                    data : [],
                    loading: true,
                }
        };

        case FETCH_VACCINE_TYPE:
            return {
                ...state,
                loading :true,
            };
        case FETCH_VACCINE_TYPE_SUCCESS:
            let vaccineTypeObjList = action.payload.data;
            let dataArray = []
            for (let id in vaccineTypeObjList) {
                let obj = vaccineTypeObjList[id];
                obj['id'] = Number.parseInt(id);
                dataArray.push(obj);
              }
            return {
                ...state,
                loading: false,
                vaccineTypes: {
                    ...state.vaccineTypes,
                    data : dataArray,
                    loading: false,
                }
            };
        case FETCH_VACCINE_TYPE_FAILED:
            return {
                ...state,
                loading: false,
                vaccineTypes: {
                    ...state.vaccineTypes,
                    data : [],
                    loading: false,
                }
        };
        default:
            return state;
    }
};

export default sessionReducer;
