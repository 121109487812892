import axios from 'axios';

export default {
    users: {
        getRegisteredUsers: payload =>
            axios
                .get(`/getRegisterCitizen?mobileNumber=${payload}`)
                .then(res => res.data),
    },
    centers: {
        getCenters: () =>
            axios
                .get(`/getCentersList`)
                .then(res => res.data),
    },
    districts: {
        getDistricts: () =>
            axios
                .get(`/districtsadmin`)
                .then(res => res.data)
    },
    vaccineType: {
        vaccineType: () =>
            axios
                .get(`/telco/getVaccineTypes`)
                .then(res => res.data)
    },
    sessions: {
        submit: payload =>
            axios
                .post(
                    `/sessions`,
                    {
                        ...payload,
                    })
                .then(res => res.data),
        update: payload =>
            axios
                .put(
                    `/sessions/${payload.id}`,
                    {
                        ...payload,
                    })
                .then(res => res.data),
        delete: payload =>
            axios
                .delete(
                    `/sessions/${payload.id}`,

                    {
                        headers: {
                            Authorization: sessionStorage.getItem('token'),
                        },
                        data: {
                            ...payload,
                        },
                    }
                )
                .then(res => res.data),
        getAll: payload =>
            axios
                .post('/sessionsAdmin/all',
                {
                    ...payload,
                })
                .then(res => res.data),
    },
    notify: {
        notifyAll: payload =>
            axios
                .post(
                    `/telco/sendSMS`,
                    {
                        ...payload,
                    })
                .then(res => res.data),
    },
    cancel: {
        cancelSessions: payload =>
            axios
                .post(
                    `/telco/cancelSession`,
                    {
                        ...payload,
                    })
                .then(res => res.data),

        getCancelledSessionList : () =>
            axios
                .get('/canncelled/sessions')
                .then(res => res.data),
    },
};
